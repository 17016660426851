import * as React from 'react'
import PropTypes from 'prop-types'

import { FlagSvg } from './flag-svg'

export default function Unknown(props) {
  return (
    <FlagSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <circle cx="256" cy="256" r="256" fill="currentColor" />
      <text x="256" y="380" textAnchor="middle" style={{ fontSize: '320px' }}>
        {props.code}
      </text>
    </FlagSvg>
  )
}

Unknown.propTypes = {
  code: PropTypes.string.isRequired,
}
