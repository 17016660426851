import * as React from 'react'

import { FlagSvg } from './flag-svg'

export default function Vietnam() {
  return (
    <FlagSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <circle cx="256" cy="256" r="256" fill="#d80027" />
      <path
        fill="#ffda44"
        d="M256 133.565l27.628 85.029h89.405l-72.331 52.55 27.628 85.03L256 303.623l-72.33 52.551 27.628-85.03-72.33-52.55h89.404z"
      />
    </FlagSvg>
  )
}
